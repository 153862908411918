<template>
  <div class="license-problem">
    <h1>License problem</h1>
    <div>
      {{ reason }}
    </div>

    <span>Please contact <a href="mailto:crm.support@cloudconnection.no">crm.support@cloudconnection.no</a> and we'll get this sorted out.</span>
  </div>
</template>

<script>
export default {
  computed: {
    reason () {
      return this.$route.params.reason
    }
  }
}
</script>

<style scoped>
.license-problem {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
